import React, { ReactNode, useEffect, useState } from 'react'
import { useModal } from '@nextui-org/react'
import styled from '@emotion/styled'

import { Colors } from '@/constants/styles/color'

const StyledPopUpContainer = styled.div`
  height: 100vh;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledPopUpBg = styled.div<{ visible: boolean; windowWidth: number }>`
  background: ${Colors.PURE_BLACK};
  opacity: ${p => (p.visible ? 0.3 : 0)};
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 480px;
  height: 100vh;
`

const StyledPopUpMain = styled.div<{
  visible: boolean
  windowWidth: number
  maxWidth?: string
  maxHeight?: string
}>`
  display: inline-block;
  max-width: ${p => (p.maxWidth ? p.maxWidth : '100%')};
  max-height: ${p => (p.maxWidth ? p.maxHeight : '100%')};
  vertical-align: middle;
  margin: auto;
  position: relative;
  z-index: 99999;
  overflowx: hidden;
  opacity: ${p => (p.visible ? 1 : 0)};
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s;
`

const StyledPopUpContents = styled.div`
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  vertical-align: middle;
  margin: auto;
  text-align: center;
  background: ${Colors.WHITE};
  border-radius: 16px;
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 32px;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.BLACK};
  background: ${Colors.WHITE};
  border-radius: 16px;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
`
interface Props {
  popUpVisible: boolean
  setPopUpVisible: (e: boolean) => void
  onClose?: () => void
  maxWidth?: string
  maxHeight?: string
  children: ReactNode
}

const Dialog = ({
  popUpVisible,
  setPopUpVisible,
  onClose,
  maxWidth,
  maxHeight,
  children,
}: Props) => {
  const { setVisible, visible } = useModal()
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    if (popUpVisible === true) {
      setVisible(true)
    } else {
      setVisible(false)
    }
    const deviceWidth = window.innerWidth
    if (deviceWidth) setWindowWidth(window.innerWidth)
  }, [popUpVisible])

  return popUpVisible ? (
    <StyledPopUpContainer id="popUp">
      <StyledPopUpBg
        visible={visible}
        windowWidth={windowWidth}
        onClick={() => setPopUpVisible(false)}
      />
      <StyledPopUpMain
        visible={visible}
        windowWidth={windowWidth}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        <StyledPopUpContents>{children}</StyledPopUpContents>
        <StyledCloseButtonContainer onClick={() => (onClose ? onClose() : setPopUpVisible(false))}>
          閉じる
        </StyledCloseButtonContainer>
      </StyledPopUpMain>
    </StyledPopUpContainer>
  ) : null
}

export default Dialog
